/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~rc-tooltip/assets/bootstrap.css";
@import "~react-dropdown/style.css";

:root {
  --local_video_container_height: 150px;
  --right_panel_width: 320px;
  --left_panel_width: 220px;
  --screen_picker_width: 800px;
  --screen_picker_height: 600px;
  --color_alabaster: #fafafa;
  --color_mercury: #e9e9e9;
  --color_alto: #dedede;
  --color_silver_chalice: #9e9e9e;
  --color_tundora: #454545;
  --color_mine_shaft_dark: #333;
  --color_mine_shaft_medium: #2d2d2d;
  --color_mine_shaft_light: rgb(15, 15, 72);
  --color_cod_gray_medium: #111;
  --color_cod_gray_light: #1b1b1b;
  --color_black: #000;
  --color_thunderbird: #d62f12;
  --color_black_medium_opacity: rgba(0, 0, 0, 0.6);
  --color_black_low_opacity: rgba(0, 0, 0, 0.25);
  --color_green: #09ff00;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;
  color: var(--color_alabaster);
  background-color: var(--color_mine_shaft_dark);
  /* background-color: var(--color_thunderbird); */
  /* --color_thunderbird: #d62f12; */

  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  line-height: 1.45;
}

/* Safari用のハックは、Chromeに適用されないようにする */
@supports (-webkit-touch-callout: none) {
  body {
    /* Safari用のハック */
    height: -webkit-fill-available;
  }
}

#root {
  height: 100%;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blurring.dimmable > .dimmer {
  background-color: rgb(58 87 62 / 60%) !important;
}
.ui.modal {
  border-radius: 10px;
}

.ui.modal > .actions {
  text-align: center;
  border-radius: 10px;
}
.ui.modal > .content {
  border-radius: 10px 10px 0 0 !important;
}

.ui.orange.card {
  background: orange;
  box-shadow: none;
}

.ui.orange.card .content {
  padding: 10px 0 6px;
}
.ui.orange.card .content .description {
  margin-bottom: 10px;
}

.ui.orange.card:hover {
  background: tomato;
  box-shadow: none;
}

.ui.card .meta {
  color: black;
}

.notifications-component {
  position: fixed;
  top: 0;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.notification-container--top-center {
  position: absolute !important;
  width: 400px !important;
  margin: 0 auto !important;
  left: 0 !important;
  right: 0 !important;
}

.notification__item {
  border-radius: 5px !important;
}

.notification__message {
  text-align: center;
}
