/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
.env {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 10000;
}
